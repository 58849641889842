<template>
  <main v-if="loading || error" class="d-flex h-100 align-items-center">
    <div class="container text-center">
      <p v-if="loading" class="text-muted">Loading...</p>
      <p v-else class="text-danger">{{ error }}</p>
    </div>
  </main>
  <div v-else class="d-flex flex-column py-5">
    <header class="py-2 border-bottom bg-light bg-gradient fixed-top">
      <div class="container">
        <div class="d-flex align-items-center justify-content-between">
          <img
            src="../assets/icon-32.png"
            width="32"
            height="32"
            class="me-2"
          />
          <div class="d-flex flex-nowrap align-items-center">
            <span class="me-2 text-muted">Hi, {{ firstName }}</span>
            <img
              :src="profilePhoto"
              :alt="user.name"
              width="32"
              height="32"
              class="rounded-circle"
            />
          </div>
        </div>
      </div>
    </header>

    <main class="pt-2 pb-4">
      <div class="container">
        <router-view />
      </div>
    </main>

    <footer class="py-2 text-muted bg-white border-top fixed-bottom">
      <div class="container">
        <div class="d-flex align-items-center justify-content-between">
          <div>
            <span class="text-muted">© 2022 </span>
            <a
              href="https://bsuperiorsystem.com"
              target="_blank"
              class="text-muted"
              >Bsup, Ltd.</a
            >
          </div>
          <div>
            <button
              type="button"
              class="btn btn-outline-danger btn-sm"
              @click="logout"
            >
              Sign Out
            </button>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { removeTokens } from "@/auth/oauth";

export default defineComponent({
  data() {
    return {
      loading: true,
      error: "",
      user: {},
    };
  },
  created() {
    this.$api
      .get("/users/me/profile")
      .then((response) => {
        this.loading = false;
        this.user = response.data?.data || {};
      })
      .catch((error) => {
        this.loading = false;
        this.error = error.message || "Error.";
      });
  },
  computed: {
    firstName() {
      return this.user.name.split(" ")[0];
    },
    profilePhoto() {
      const name = encodeURIComponent(
        this.user.name.match(/\b(\w)/g).join(" ")
      );
      return `https://ui-avatars.com/api/?name=${name}&background=random`;
    },
  },
  methods: {
    logout() {
      removeTokens();
      this.$router.push({ name: "login" });
    },
  },
});
</script>
