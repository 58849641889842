<template>
  <h1 class="display-1">Resources</h1>
  <template v-if="!initialLoading">
    <p>You may select a resource to load into the current sheet.</p>
    <form @submit.prevent="loadEntities">
      <div class="mb-3">
        <label for="resourceSelect" class="form-label">Resource</label>
        <select
          id="resourceSelect"
          class="form-select"
          v-model="currentResource"
          :disabled="loadingResource"
          @change="loadMeta"
          required
        >
          <option value="" disabled>&lt;Resource&gt;</option>
          <option v-for="(resource, key) in resources" :key="key" :value="key">
            {{ resource.name }}
          </option>
        </select>
      </div>
      <template v-if="currentMetaParsed">
        <has-many-field
          v-if="filterableFields"
          #default="{ rowData, iteration }"
          v-model="currentFilter"
          label="Filters"
          :disabled="loadingResource"
        >
          <div class="row gx-2">
            <div class="col">
              <label :for="`filterField${iteration}`" class="form-label"
                >Field</label
              >
              <select
                :id="`filterField${iteration}`"
                class="form-select"
                v-model="rowData.field"
                required
              >
                <option value="" disabled>&lt;Field&gt;</option>
                <option v-for="key in filterableFields" :key="key" :value="key">
                  {{ key }}
                </option>
              </select>
            </div>
            <div class="col">
              <label :for="`filterOperator${iteration}`" class="form-label"
                >Operator</label
              >
              <select
                :id="`filterOperator${iteration}`"
                class="form-select"
                v-model="rowData.operator"
                required
              >
                <option value="" disabled>&lt;Operator&gt;</option>
                <option
                  v-for="(name, key) in filterOperators"
                  :key="key"
                  :value="key"
                >
                  {{ name }}
                </option>
              </select>
            </div>
            <div class="col">
              <label :for="`filterValue${iteration}`" class="form-label"
                >Value</label
              >
              <input
                :id="`filterValue$${iteration}`"
                type="text"
                class="form-control"
                v-model="rowData.value"
                required
              />
            </div>
          </div>
        </has-many-field>

        <has-many-field
          v-if="sortableFields"
          #default="{ rowData, iteration }"
          v-model="currentSort"
          label="Sorting"
          :disabled="loadingResource"
        >
          <div class="row gx-2">
            <div class="col">
              <label :for="`sortField${iteration}`" class="form-label"
                >Field</label
              >
              <select
                :id="`sortField${iteration}`"
                class="form-select"
                v-model="rowData.field"
                required
              >
                <option value="" disabled>&lt;Field&gt;</option>
                <option v-for="key in sortableFields" :key="key" :value="key">
                  {{ key }}
                </option>
              </select>
            </div>
            <div class="col">
              <label :for="`sortDirection${iteration}`" class="form-label"
                >Direction</label
              >
              <select
                :id="`sortDirection${iteration}`"
                class="form-select"
                v-model="rowData.direction"
                required
              >
                <option value="" disabled>&lt;Direction&gt;</option>
                <option value="asc">Ascending</option>
                <option value="desc">Descending</option>
              </select>
            </div>
          </div>
        </has-many-field>

        <fieldset class="mb-3">
          <legend>Pagination</legend>
          <div class="row gx-2 mb-2">
            <div class="col">
              <label for="paginationPerPage" class="form-label">Per page</label>
              <input
                id="paginationPerPage"
                type="number"
                class="form-control"
                v-model="currentPagination.perPage"
                :disabled="loadingResource"
              />
            </div>
            <div class="col">
              <label for="paginationPage" class="form-label">Page</label>
              <input
                id="paginationPage"
                type="number"
                class="form-control"
                v-model="currentPagination.page"
                :disabled="loadingResource"
              />
            </div>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              id="noPaginationInput"
              type="checkbox"
              v-model="noPagination"
              :disabled="loadingResource"
            />
            <label class="form-check-label" for="noPaginationInput">
              No Pagination
            </label>
          </div>
        </fieldset>
      </template>

      <p v-if="errorLoadingResource" class="form-text text-danger">
        {{ errorLoadingResource }}
      </p>

      <button
        type="submit"
        class="btn btn-primary mb-2"
        :disabled="globalLoading"
      >
        {{ loadingResource ? "Loading..." : "Load" }}
      </button>
    </form>

    <template v-if="resourceIsSynced">
      <hr />

      <p>Click to refresh the dropdown items.</p>
      <p v-if="errorRefreshDropdowns" class="form-text text-danger">
        {{ errorRefreshDropdowns }}
      </p>
      <button
        type="button"
        class="btn btn-primary mb-3"
        @click="refreshDropdowns"
        :disabled="globalLoading"
      >
        {{ loadingDropdowns ? "Refreshing..." : "Refresh Dropdowns" }}
      </button>

      <hr />

      <p>Click to add a blank row.</p>
      <button
        type="button"
        class="btn btn-primary mb-3"
        @click="addBlankRow"
        :disabled="globalLoading"
      >
        {{ loadingBlankRow ? "Adding..." : "Add Blank row" }}
      </button>

      <hr />

      <p>Choose an action to run.</p>
      <form @submit.prevent="runAction">
        <div class="mb-3">
          <label for="actionSelect" class="form-label">Action</label>
          <select
            id="actionSelect"
            class="form-select"
            v-model="selectedAction"
            :disabled="loadingAction"
            required
          >
            <option value="" disabled>&lt;Action&gt;</option>
            <option v-for="action in actions" :key="action" :value="action">
              {{ action }}
            </option>
          </select>
        </div>

        <p v-if="errorAction" class="form-text text-danger">
          {{ errorAction }}
        </p>

        <p v-if="warningAction" class="form-text text-warning">
          {{ warningAction }}
        </p>

        <p v-if="hasValidationError">
          <button
            type="button"
            class="btn btn-outline-secondary mb-2 me-2"
            @click="navigateValidationError('previous')"
            :disabled="globalLoading"
          >
            Previous Error
          </button>
          <button
            type="button"
            class="btn btn-outline-secondary mb-2 me-2"
            @click="navigateValidationError('next')"
            :disabled="globalLoading"
          >
            Next Error
          </button>
          <button
            type="button"
            class="btn btn-outline-secondary mb-2 me-2"
            @click="navigateValidationError('clearActive')"
            :disabled="globalLoading"
          >
            Clear Cell Error
          </button>
          <button
            type="button"
            class="btn btn-outline-secondary mb-2"
            @click="navigateValidationError('clearAll')"
            :disabled="globalLoading"
          >
            Clear All Errors
          </button>
        </p>

        <button
          type="submit"
          class="btn btn-primary mb-2"
          :disabled="globalLoading"
        >
          {{ loadingAction ? "Running..." : "Run" }}
        </button>
      </form>

      <hr />

      <p>Select a cell an click to edit.</p>
      <form @submit.prevent="editingCell ? updateCell() : editCell()">
        <div v-if="editingCell" class="mb-3">
          <label for="cellInput" class="form-label">Cell Value</label>
          <select
            v-if="selectedCell.type === inputTypes.select"
            id="cellInput"
            class="form-select"
            v-model="selectedCell.value"
            :disabled="loadingEditCell"
          >
            <option value="" disabled>&lt;Empty&gt;</option>
            <option
              v-for="(text, key) in selectedCell.items"
              :key="key"
              :value="key"
            >
              {{ text }}
            </option>
          </select>
          <template
            v-else-if="
              [inputTypes.checkbox, inputTypes.radio].includes(
                selectedCell.type
              )
            "
          >
            <div
              class="form-check"
              v-for="(text, key) in selectedCell.items"
              :key="key"
            >
              <input
                class="form-check-input"
                :id="`cellInput${key}`"
                :type="selectedCell.type"
                :value="key"
                v-model="selectedCell.value"
                :disabled="loadingEditCell"
              />
              <label class="form-check-label" :for="`cellInput${key}`">
                {{ text }}
              </label>
            </div>
          </template>
          <textarea
            v-else-if="selectedCell.type === inputTypes.textarea"
            id="cellInput"
            class="form-control"
            rows="3"
            v-model="selectedCell.value"
            :disabled="loadingEditCell"
          ></textarea>
          <input
            v-else
            id="cellInput"
            :type="selectedCell.type"
            class="form-control"
            v-model="selectedCell.value"
            :disabled="loadingEditCell"
          />
        </div>

        <p v-if="errorEditCell" class="form-text text-danger">
          {{ errorEditCell }}
        </p>

        <button
          type="submit"
          class="btn btn-primary mb-2"
          :disabled="globalLoading"
        >
          {{ editingCell ? "Set Cell" : "Edit Cell" }}
        </button>
        <button
          v-if="editingCell"
          type="button"
          class="btn btn-outline-secondary mb-2 ms-2"
          @click="cancelEditingCell"
          :disabled="loadingEditCell"
        >
          Cancel
        </button>
      </form>

      <template v-if="currentMeta?.relations">
        <hr />

        <p>Select a row and related resource to view on a new sheet.</p>
        <form @submit.prevent="loadRelatedEntity">
          <div class="mb-3">
            <label for="relatedResourceSelect" class="form-label"
              >Related Resource</label
            >
            <select
              id="relatedResourceSelect"
              class="form-select"
              v-model="selectedRelatedResource"
              :disabled="loadingRelatedResource"
              required
            >
              <option value="" disabled>&lt;Resource&gt;</option>
              <option
                v-for="(relation, key) in currentMeta?.relations"
                :key="key"
                :value="key"
              >
                {{ relation }}
              </option>
            </select>
          </div>

          <p v-if="errorLoadingRelatedResource" class="form-text text-danger">
            {{ errorLoadingRelatedResource }}
          </p>

          <button
            type="submit"
            class="btn btn-primary mb-2"
            :disabled="globalLoading"
          >
            {{ loadingRelatedResource ? "Loading..." : "Load" }}
          </button>
        </form>
      </template>

      <hr />

      <p>
        Select a template to generate a report for the selected row or all rows.
      </p>
      <form @submit.prevent="generateReport">
        <div class="mb-3">
          <label for="reportTypeSelect" class="form-label">For</label>
          <select
            id="reportTypeSelect"
            class="form-select"
            v-model="selectedReportType"
            :disabled="loadingReport"
            required
          >
            <option value="" disabled>&lt;For&gt;</option>
            <option value="single_instance">Selected Row</option>
            <option value="multiple_instances">All Rows</option>
          </select>
        </div>

        <div class="mb-3">
          <label for="reportTemplateSelect" class="form-label">Template</label>
          <select
            id="reportTemplateSelect"
            class="form-select"
            v-model="selectedReportTemplate"
            :disabled="loadingReport"
            required
          >
            <option value="" disabled>&lt;Template&gt;</option>
            <option
              v-for="template in filteredReportTemplates"
              :key="template.id"
              :value="template.id"
            >
              {{ template.label }}
              <template v-if="template.description">
                - {{ template.description }}
              </template>
            </option>
          </select>
        </div>

        <p v-if="errorGeneratingReport" class="form-text text-danger">
          {{ errorGeneratingReport }}
        </p>

        <button
          type="submit"
          class="btn btn-primary mb-2"
          :disabled="globalLoading"
        >
          {{ loadingReport ? "Generating..." : "Generate" }}
        </button>
      </form>

      <hr />

      <p>Click to resize the table automatically.</p>
      <button
        type="button"
        class="btn btn-primary mb-3"
        @click="resizeTable"
        :disabled="globalLoading"
      >
        {{ loadingResize ? "Resizing..." : "Auto resize table" }}
      </button>

      <hr />

      <p>Click to load sample data for testing purposes.</p>
      <button
        type="button"
        class="btn btn-primary mb-3"
        @click="loadSampleData"
        :disabled="globalLoading"
      >
        Sample Data
      </button>
    </template>
  </template>
  <p v-else class="text-danger">{{ initialError }}</p>
</template>

<script>
import { defineComponent } from "vue";
import { has, merge, sortBy } from "lodash-es";
import HasManyField from "@/components/HasManyField";
import { initialize, actions, inputTypes } from "@/utilities/template-common";
import {
  writeArrayToSheet,
  refreshItemsColumns,
  getRowsToSync,
  afterSync,
  getFspValues,
  setFspValues,
  getValuesOfRichCell,
  setValueOfRichCell,
  getIdOfSelectedRow,
  addAndWriteToSheet,
  hasAnyHiddenRow,
  selectOrClearCellWithError,
  generateReport,
  insertBlankRow,
  resizeTable,
  temporarySampleData,
} from "@/utilities/default-template";

export default defineComponent({
  components: { HasManyField },
  data() {
    return {
      initialLoading: true,
      initialError: "",
      resources: {},
      currentResource: "",
      currentResourceOnSheet: "",
      loadingResource: false,
      errorLoadingResource: "",

      loadingDropdowns: false,
      errorRefreshDropdowns: "",

      actions: actions,
      selectedAction: "",
      loadingAction: false,
      errorAction: "",
      warningAction: "",

      filterOperators: Object.freeze({
        $eq: "Equal to",
        $ne: "Not equal to",
        $gt: "Greater than",
        $lt: "Less than",
        $gte: "Greater than or equal to",
        $lte: "Less than or equal to",
        $regex: "Match regular expression",
        $like: "Match pattern",
      }),

      hasValidationError: false,

      inputTypes: inputTypes,
      editingCell: false,
      selectedCell: null,
      loadingEditCell: false,
      errorEditCell: "",

      selectedRelatedResource: "",
      loadingRelatedResource: false,
      errorLoadingRelatedResource: "",

      selectedReportType: "",
      selectedReportTemplate: "",
      loadingReport: false,
      errorGeneratingReport: "",

      loadingBlankRow: false,
      loadingResize: false,
      loadingSampleData: false,
    };
  },
  computed: {
    resourceIsSynced() {
      return (
        this.currentResource &&
        this.currentResource === this.currentResourceOnSheet
      );
    },
    globalLoading() {
      return (
        this.loadingResource ||
        this.loadingDropdowns ||
        this.loadingBlankRow ||
        this.loadingAction ||
        this.loadingRelatedResource ||
        this.loadingEditCell ||
        this.loadingReport ||
        this.loadingResize ||
        this.loadingSampleData
      );
    },
    currentMeta: {
      get() {
        return this.resources[this.currentResource]?.meta;
      },
      set(newValue) {
        this.resources[this.currentResource].meta = newValue;
      },
    },
    currentMetaParsed: {
      get() {
        return this.resources[this.currentResource]?.metaParsed;
      },
      set(newValue) {
        this.resources[this.currentResource].metaParsed = newValue;
      },
    },
    currentFilter: {
      get() {
        return this.resources[this.currentResource]?.filter;
      },
      set(newValue) {
        this.resources[this.currentResource].filter = newValue;
      },
    },
    currentSort: {
      get() {
        return this.resources[this.currentResource]?.sort;
      },
      set(newValue) {
        this.resources[this.currentResource].sort = newValue;
      },
    },
    currentPagination: {
      get() {
        return this.resources[this.currentResource]?.pagination;
      },
      set(newValue) {
        this.resources[this.currentResource].pagination = newValue;
      },
    },
    noPagination: {
      get() {
        return !this.currentPagination.page && !this.currentPagination.perPage;
      },
      set(newValue) {
        this.currentPagination = newValue
          ? { page: null, perPage: null }
          : this.mapDefaultPagination();
      },
    },
    filterableFields() {
      return this.currentMeta?.filter?.filterable_fields;
    },
    sortableFields() {
      return this.currentMeta?.sort?.sortable_fields;
    },
    filteredReportTemplates() {
      return this.currentResource && this.selectedReportType
        ? (this.resources[this.currentResource]?.templates || []).filter(
            (template) => template.type === this.selectedReportType
          )
        : [];
    },
  },
  created() {
    this.$api
      .get("/users/me?with[]=resources&with[]=resources.excel_report_templates")
      .then((response) => {
        this.initialLoading = false;
        this.resources = Object.fromEntries(
          sortBy(response.data?.data?.resources || [], ["label"]).map(
            ({ label, name, meta, meta_excel, excel_report_templates }) => [
              name,
              {
                name: label,
                meta: merge(
                  {},
                  typeof meta === "string" ? JSON.parse(meta) : meta,
                  typeof meta_excel === "string"
                    ? JSON.parse(meta_excel)
                    : meta_excel
                ),
                metaParsed: false,
                filter: {},
                sort: [],
                pagination: {},
                templates: excel_report_templates,
              },
            ]
          )
        );

        // Initialize template
        initialize(this.setCurrentResource);
      })
      .catch((error) => {
        this.initialLoading = false;
        this.initialError = error.message || "Error.";
      });
  },
  methods: {
    mapDefaultFilter(filter) {
      const result = [];
      Object.entries(filter).forEach(([field, expression]) => {
        if (
          typeof expression === "object" &&
          !Array.isArray(expression) &&
          expression !== null
        ) {
          Object.entries(expression).forEach(([operator, value]) => {
            result.push({ field, operator, value });
          });
        } else {
          result.push({ field, operator: "$eq", expression });
        }
      });
      return result;
    },
    mapDefaultSort(sort) {
      return sort.map((value) => {
        const [field, direction] = value.split(":", 2);
        return { field: field, direction: direction || "asc" };
      });
    },
    mapDefaultPagination(pagination) {
      return {
        page: has(pagination, "page") ? pagination.page : 1,
        perPage: has(pagination, "per_page") ? pagination.per_page : 15,
      };
    },
    async setDefaultValues() {
      let filter, sort, pagination;

      if (this.resourceIsSynced) {
        try {
          ({ filter, sort, pagination } = JSON.parse(await getFspValues()));
        } catch (e) {
          // continue regardless of error
        }
      }

      this.currentFilter = this.mapDefaultFilter(
        filter || this.currentMeta?.filter?.default_filter || {}
      );
      this.currentSort = this.mapDefaultSort(
        sort || this.currentMeta?.sort?.default_sort || []
      );
      this.currentPagination = this.mapDefaultPagination(
        pagination || this.currentMeta?.pagination?.default_pagination || {}
      );
    },
    setCurrentResource(name) {
      if (Object.prototype.hasOwnProperty.call(this.resources, name)) {
        this.currentResource = name;
        this.currentResourceOnSheet = name;
        this.loadMeta();
      } else {
        this.currentResource = "";
        this.currentResourceOnSheet = "";
      }
    },
    loadMeta() {
      if (this.currentMetaParsed) {
        this.loadingResource = false;
        this.errorLoadingResource = "";
        return;
      }

      this.loadingResource = true;
      this.errorLoadingResource = "";

      Promise.all([this.setDefaultValues(), ...this.loadMetaAttributes()])
        .then(() => {
          this.loadingResource = false;
          this.currentMetaParsed = true;
        })
        .catch((error) => {
          this.loadingResource = false;
          this.errorLoadingResource = error.message || "Error.";
        });
    },
    loadMetaAttributes() {
      let promises = [];

      if (this.currentMeta.attributes) {
        for (const attribute in this.currentMeta.attributes) {
          if (
            Object.prototype.hasOwnProperty.call(
              this.currentMeta.attributes,
              attribute
            ) &&
            this.currentMeta.attributes[attribute].link
          ) {
            promises.push(
              this.$api
                .get(this.currentMeta.attributes[attribute].link)
                .then((result) => {
                  this.currentMeta.attributes[attribute].items =
                    result?.data?.data || [];
                  return Promise.resolve(true);
                })
            );
          }
        }
      }

      return promises;
    },
    refreshDropdowns() {
      this.loadingDropdowns = true;
      this.errorRefreshDropdowns = "";
      Promise.all(this.loadMetaAttributes())
        .then(() => refreshItemsColumns(this.currentMeta))
        .catch((error) => {
          this.errorRefreshDropdowns = error.message || "Error.";
        })
        .finally(() => {
          this.loadingDropdowns = false;
        });
    },
    mapFilter() {
      let filter = {};
      this.currentFilter.forEach((item) => {
        filter[item.field] = { [item.operator]: item.value };
      });
      return filter;
    },
    mapSort() {
      return this.currentSort.map((item) => `${item.field}:${item.direction}`);
    },
    fetchEntities() {
      return this.$api.get(this.currentResource, {
        params: {
          filter: this.mapFilter(),
          sort: this.mapSort(),
          per_page: this.currentPagination.perPage,
          page: this.currentPagination.page,
          with: this.currentMeta.with,
        },
      });
    },
    loadEntities() {
      this.loadingResource = true;
      this.errorLoadingResource = "";
      this.fetchEntities()
        .then((response) => {
          return writeArrayToSheet(
            response.data.data,
            this.currentMeta,
            this.currentResource
          )
            .then(() => {
              this.currentResourceOnSheet = this.currentResource;
              this.loadingResource = false;
              this.hasValidationError = false;
              const params = response.config.params;
              setFspValues(
                JSON.stringify({
                  filter: params.filter,
                  sort: params.sort,
                  pagination: { page: params.page, per_page: params.per_page },
                })
              );
            })
            .catch(() =>
              Promise.reject({
                message: "Error on writing data to the sheet.",
              })
            );
        })
        .catch((error) => {
          this.loadingResource = false;
          this.errorLoadingResource = error.message || "Error.";
        });
    },
    runAction() {
      this.loadingAction = true;
      this.errorAction = "";
      this.warningAction = "";
      this.hasValidationError = false;
      getRowsToSync()
        .then((rows) => {
          let nothingToSync = false;
          if (rows && rows.length) {
            const rowsToSync =
              this.selectedAction === actions.all
                ? rows.filter((row) =>
                    Object.values(actions).includes(row.action)
                  )
                : rows.filter((row) => row.action === this.selectedAction);

            if (rowsToSync && rowsToSync.length) {
              Promise.allSettled(
                rowsToSync.map((row) =>
                  this.getActionRequest(row)
                    .then((response) =>
                      Promise.resolve({
                        row: row,
                        message: response?.data?.message,
                        data: response?.data?.data,
                      })
                    )
                    .catch((error) => {
                      if (error?.response?.data?.errors) {
                        this.hasValidationError = true;
                      }

                      return Promise.reject({
                        row: row,
                        message: error?.message,
                        data: error?.response?.data,
                      });
                    })
                )
              ).then((results) => {
                this.loadingAction = false;
                if (hasAnyHiddenRow()) {
                  this.warningAction =
                    "The add-in skipped one or more rows because they're hidden." +
                    " If you'd like to consider them, unhide those rows and try again.";
                }

                afterSync(results, this.currentMeta);
              });
            } else {
              nothingToSync = true;
            }
          } else {
            nothingToSync = true;
          }

          if (nothingToSync) {
            return Promise.reject(
              "This action doesn't apply on any rows. Load and modify the data first."
            );
          }
        })
        .catch((error) => {
          this.loadingAction = false;
          this.errorAction = error || "Error.";
        });
    },
    getActionRequest(row) {
      switch (row.action) {
        case actions.add:
          return this.$api.post(
            this.currentResource,
            { data: row.attributes },
            { params: { with: this.currentMeta.with } }
          );
        case actions.sync:
          return this.$api.put(
            `${this.currentResource}/${row.id}`,
            {
              data: row.attributes,
              retrieved_at: row.timestamp,
            },
            { params: { with: this.currentMeta.with } }
          );
        case actions.delete:
          return this.$api.delete(`${this.currentResource}/${row.id}`);
        case actions.clear:
          // TODO
          return Promise.resolve({
            data: { message: "The row has been cleared!", data: true },
          });
      }
    },
    navigateValidationError(action) {
      if (action === "clearAll") {
        this.hasValidationError = false;
      }

      selectOrClearCellWithError(action);
    },
    addBlankRow() {
      this.loadingBlankRow = true;
      insertBlankRow().finally(() => {
        this.loadingBlankRow = false;
      });
    },
    editCell() {
      this.loadingEditCell = true;
      this.errorEditCell = "";
      getValuesOfRichCell(this.currentMeta)
        .then((cell) => {
          this.selectedCell = cell;
          this.editingCell = true;
          this.loadingEditCell = false;
        })
        .catch((error) => {
          this.errorEditCell = error || "Error.";
          this.loadingEditCell = false;
        });
    },
    updateCell() {
      this.loadingEditCell = true;
      this.errorEditCell = "";
      setValueOfRichCell(this.selectedCell)
        .then(() => {
          this.editingCell = false;
          this.loadingEditCell = false;
        })
        .catch((error) => {
          this.errorEditCell = error || "Error.";
          this.loadingEditCell = false;
        });
    },
    cancelEditingCell() {
      this.editingCell = false;
      this.errorEditCell = "";
    },
    fetchEntityOfSelectedRow(params) {
      return getIdOfSelectedRow()
        .catch((error) =>
          Promise.reject({ message: error || "Select a row first." })
        )
        .then((id) =>
          this.$api.get(`${this.currentResource}/${id}`, { params })
        );
    },
    loadRelatedEntity() {
      this.loadingRelatedResource = true;
      this.errorLoadingRelatedResource = "";
      this.fetchEntityOfSelectedRow({
        with: [this.selectedRelatedResource],
      })
        .then((response) => {
          return addAndWriteToSheet(
            response.data.data,
            this.currentMeta,
            this.currentResource,
            this.selectedRelatedResource
          )
            .then(() => {
              this.loadingRelatedResource = false;
            })
            .catch((error) =>
              Promise.reject({
                message: error, // "Error on writing data to a new sheet.",
              })
            );
        })
        .catch((error) => {
          this.loadingRelatedResource = false;
          this.errorLoadingRelatedResource = error || "Error.";
        });
    },
    generateReport() {
      this.loadingReport = true;
      this.errorGeneratingReport = "";

      const template = this.filteredReportTemplates.find(
        (template) => template.id === this.selectedReportTemplate
      );

      let idOfSelectedRow;
      let queries = template.queries;
      queries = typeof queries === "string" ? JSON.parse(queries) : queries;
      queries = queries.map(async (query) =>
        this.$api.get(
          query.includes("<id>")
            ? query.replace(
                "<id>",
                idOfSelectedRow
                  ? idOfSelectedRow
                  : (idOfSelectedRow = await getIdOfSelectedRow())
              )
            : query
        )
      );

      Promise.all(queries)
        .then((values) =>
          generateReport(
            values,
            this.currentMeta,
            this.currentResource,
            template
          )
        )
        .then(() => {
          this.loadingReport = false;
        })
        .catch((error) => {
          this.loadingReport = false;
          this.errorGeneratingReport = error?.message || error;
        });
    },
    resizeTable() {
      this.loadingResize = true;
      resizeTable().finally(() => {
        this.loadingResize = false;
      });
    },
    loadSampleData() {
      this.loadingSampleData = true;
      temporarySampleData().finally(() => {
        this.loadingSampleData = false;
      });
    },
  },
});
</script>
