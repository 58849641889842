
import { defineComponent } from "vue";
import { openLoginDialog } from "@/utilities/office-apis-helpers";

export default defineComponent({
  data() {
    return {
      loading: false,
      error: "",
    };
  },
  methods: {
    async doLogin() {
      await openLoginDialog(this.onSuccess, this.onError);
      this.loading = true;
      this.error = "";
    },
    onError(message: string): void {
      this.loading = false;
      this.error = message;
    },
    onSuccess(): void {
      this.loading = false;
      this.error = "";

      this.$router.push({ name: "home" });
    },
  },
});
