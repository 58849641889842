/* global Excel */

// Rename the given sheet to sheetName
// If the name already exist, add a numeric postfix
// Inputs:
//	+ sheets: All sheets
//	+ sheet: the sheet whose name will be changed
//	+ sheetName: The new sheet name
export const renameSheet = (context, sheets, sheet, sheetName) => {
  let i = 1;
  let shName = sheetName;
  let sheetNameFound = true;
  while (sheetNameFound) {
    sheetNameFound = false;
    sheets.items.forEach(function (sh) {
      if (shName == sh.name && sheet.name != sh.name) {
        // Skip the current sheet
        sheetNameFound = true;
      }
    });
    if (!sheetNameFound) break;
    shName = sheetName + " " + i;
    i = i + 1;
  }
  sheet.name = shName;
  return shName;
};

// Rename the given sheet to sheetName
// If the name already exist, add a numeric postfix
// Inputs:
//	+ sheets: All sheets
//	+ sheet: the sheet whose name will be changed
//	+ sheetName: The new sheet name
export const renameSheetOld = async (sheet, sheetName) => {
  return await Excel.run(async (context) => {
    let i = 1;
    let shName = sheetName;
    let sheetNameFound = true;
    const sheets = context.workbook.worksheets;
    sheets.load("items/name");
    await context.sync();
    while (sheetNameFound) {
      sheetNameFound = false;
      sheets.items.forEach(function (sh) {
        if (shName == sh.name && sheet.name != sh.name) {
          // Skip the current sheet
          sheetNameFound = true;
        }
      });
      if (!sheetNameFound) break;
      shName = sheetName + " " + i;
      i = i + 1;
    }
    sheet.name = shName;
    await context.sync();
    return Promise.resolve(shName);
  });
};

// Add a new worksheet
// Return true if the sheet is added
export const addWorksheet = async () => {
  return await Excel.run(async (context) => {
    const sheets = context.workbook.worksheets;
    const sheet = sheets.add();
    sheet.activate();
    sheet.load("name, position");

    await context.sync();
    return true;
    //console.log(`Added worksheet named "${sheet.name}" in position ${sheet.position}`);
  });
};

// Add a new worksheet and name it to sheetName
// But if the sheet already exists, don't do anything
// Return true if sheet is created. Return false, if sheet exists
export const addWorksheetIfNotExist = async (sheetName) => {
  const shExistence = await sheetExists(sheetName);
  if (shExistence) {
    return false;
  } else {
    return await Excel.run(async (context) => {
      const sheets = context.workbook.worksheets;
      sheets.add(sheetName);
      await context.sync();
      return true;
    });
  }
};

// Check whether the given sheet exists
// Inputs:
//	+ sheetName: The sheet name to check
export const sheetExists = async (sheetName) => {
  return await Excel.run(async (context) => {
    try {
      const sh = context.workbook.worksheets.getItem(sheetName);
      sh.load();
      await context.sync();
      //sh.delete();
      //console.log("Delete ---");
      //return false;
      return true;
    } catch (e) {
      return false;
    }
  });
};

// Name the given range (rng) of the active sheet to rngName with the workbook scope
// If the name already exists, then delete it
// Inputs:
//	+ rng: The range to be named.
//	+ rngName: The name of the to-be-named range
// Return true, if the range is named
export const nameRangeWithWbScope = async (rngAddress, rngName) => {
  return await Excel.run(async (context) => {
    const wb = context.workbook;
    const sheet = context.workbook.worksheets.getActiveWorksheet();
    const nm = wb.names.getItem(rngName);

    // Delete the name if already exists
    try {
      nm.load("formula");
      await context.sync();
      nm.delete();
    } catch (e) {
      //console.log("Name does not exists");
    }

    const rng = sheet.getRange(rngAddress);

    // Add the name
    wb.names.add(rngName, rng);

    return true;
  });
};

// Name the given range (rng) to rngName on the active sheet
// If the name already exists, then delete it and re-add
// Inputs:
//	+ rngAddress: The address of the range to be named.
//	+ rngName: The name of the to-be-named range
//
// Return true if the range is named
export const nameRangeOnActivesheet = async (rngAddress, rngName) => {
  if (rngName.length >= 255)
    console.log("Error #ERQS. More than 255 characters in range name");
  return await Excel.run(async (context) => {
    const sheet = context.workbook.worksheets.getActiveWorksheet();
    const nm = sheet.names.getItem(rngName);

    // Delete the existing name, if any
    try {
      nm.load("formula");
      await context.sync();
      nm.delete();
    } catch (e) {
      // Do nothing
    }

    const rng = sheet.getRange(rngAddress);

    // Add the name to the worksheet scope
    sheet.names.add(rngName, rng);
    await context.sync();
    return true;
  });
};

// Get the value inside a named range which has the workbook scope
// Returns false if the named range does not exists. Otherwise, returns a two dimensional array of values
export const getValuesOfNamedRange = async (rangeName) => {
  return await Excel.run(async (context) => {
    const wb = context.workbook;
    const nameItem = wb.names.getItemOrNullObject(rangeName);
    nameItem.load();
    await context.sync();
    if (!nameItem.isNullObject) {
      const rng = nameItem.getRange();
      rng.load("values");
      await context.sync();
      //console.log("Returning the language in the temporary storage sheet:" + rng.values[0][0]);
      return rng.values; //[0][0]
    } else {
      return false;
    }
  });
};

// Delete the given range name which has workbook scope
// Src: https://raw.githubusercontent.com/OfficeDev/office-js-snippets/prod/samples/excel/34-named-item/create-and-remove-named-item.yaml
// Not beign tested
export const deleteRangeNameWithWbScope = async (rngName) => {
  return await Excel.run(async (context) => {
    const wb = context.workbook;
    try {
      const nm = wb.names.getItem(rngName);
      nm.load();
      await context.sync();
      nm.delete();
      await context.sync();
      //console.log("Deleted");
    } catch (e) {
      // Do nothing
    }
    return true;
  });
};

// Delete the given range name in the given sheet which has worksheet scope
export const deleteRangeNameWithShScope = async (context, sheet, rngName) => {
  try {
    const nm = sheet.names.getItem(rngName);
    nm.load();
    await context.sync();
    nm.delete();
    await context.sync();
    //console.log("Deleted");
  } catch (e) {
    // Do nothing
  }
};

// Get the value of the following named cell in the given sheet
// Return the value if the range is found. Otherwise, return false
export const getNamedRangeValue = async (context, sheet, cellName) => {
  try {
    const rng = sheet.getRange(cellName);
    rng.load("values");
    await context.sync();
    //console.log("getNamed: ", cellName, rng.values);
    return rng.values; //[0][0];
  } catch (e) {
    // console.log("Resource ame does not exists");
    return false;
  }
};

// Name the given range as rngName.
// Add the val to the range
// Inputs:
//	+ rng: The range to be named
//	+ rngName: The ranged to be named this
//	+ val: The value to be inserted in the range
//	+ dvMsg: The input message in the data validation of the range
//
// Return true, if the range is set
export const setRangeName = (context, sheet, rng, rngName, val, dvMsg) => {
  sheet.names.add(rngName, rng); // The name is added to the current sheet scope
  rng.dataValidation.prompt = {
    message: dvMsg,
    showPrompt: true, // The default is 'false'.
    title: "",
  };
  rng.values = val; // Name of the current resource
};

// Name the given range on the active sheet as rngName. If the name already exists, delete it and re-add
// Add the val to the range
// Inputs:
//	+ rangeAddress: The address of the range to be named
//	+ rngName: The ranged to be named this
//	+ val: The value to be inserted in the range
//	+ dvMsg: The input message in the data validation of the range
//
// Return true, if the range is set
export const setRangeNameOnActivesheet = async (
  context,
  sheet,
  rangeAddress,
  rngName,
  val,
  dvMsg
) => {
  await deleteRangeNameWithShScope(context, sheet, rngName); // Delete the range name if exists.
  const rng = sheet.getRange(rangeAddress);
  sheet.names.add(rngName, rng); // The name is added to the current sheet scope
  rng.dataValidation.prompt = {
    message: dvMsg,
    showPrompt: true, // The default is 'false'.
    title: "",
  };
  rng.values = val; // Name of the current resource
  await context.sync();
  return Promise.resolve(true);
};

// Get the list (like dropdown items) in the data validation of the given cell in the active sheet
// If the source of the list is another range, get the list from the other range.
// If no list, return null
// Inputs:
//	+ cell: The cell with the data validation to get the list from
//
// Pre-req:
//	+ The data validation of the cell should b previously loaded
export const getDtVlList = async (cell) => {
  return await Excel.run(async (context) => {
    const sheet = context.workbook.worksheets.getActiveWorksheet();
    //const cell = sheet.getRange(cellAddress);
    ///cell.load("dataValidation/rule/list/source");
    //await context.sync();
    const src = cell?.dataValidation?.rule?.list?.source;
    //console.log("src", cell);
    if (src === undefined) return; // If no list, then return
    let re;
    // If the source is another range, get the list from the other range
    if (typeof src === "string" || src instanceof String) {
      if (src.charAt(0) === "=") {
        const rngName = src.replace("=", ""); // Extract the range name from the source
        const rng = sheet.getRange(rngName); // Set the range including the list
        rng.load("values");
        await context.sync();
        re = [];
        for (const v of rng.values) {
          re.push(v[0]);
        }
        // Else return the source as an array
      } else {
        re = src.split(",");
      }
    }
    return re;
  });
};

// Get the list (like dropdown items) in the data validation of the given cell on the active sheet
// If the source of the list is another range, get the list from the other range.
// If no list, return null
// Inputs:
//	+ cellAddress: The address of the cell with the data validation to get the list from
// Note: the difference between this function and getDtVlList() is that this function gets cell address, not cell
export const getDtVlList2 = async (cellAddress) => {
  return await Excel.run(async (context) => {
    const sheet = context.workbook.worksheets.getActiveWorksheet();
    const cell = sheet.getRange(cellAddress);
    cell.load("dataValidation/rule/list/source");
    await context.sync();
    const src = cell?.dataValidation?.rule?.list?.source;
    if (src === undefined) return; // If no list, then return
    //console.log(src);
    let re;
    // If the source is another range, get the list from the other range
    if (typeof src === "string" || src instanceof String) {
      if (src.charAt(0) === "=") {
        const rngName = src.replace("=", ""); // Extract the range name from the source
        const rng = sheet.getRange(rngName); // Set the range including the list
        rng.load("values");
        await context.sync();
        re = [];
        for (const v of rng.values) {
          re.push(v[0]);
        }
        // Else return the source as an array
      } else {
        re = src.split(",");
      }
    }
    //console.log(re);
    return re;
  });
};

// Convert column letter to number
export const convertLetterToNumber = (str) => {
  str = str.toUpperCase();
  let out = 0;
  const len = str.length;
  for (let pos = 0; pos < len; pos++) {
    out += (str.charCodeAt(pos) - 64) * Math.pow(26, len - pos - 1);
  }
  return out - 1;
};

// Convert column number to letter
export const convertNumberToLetter = (n) => {
  const ordA = "a".charCodeAt(0);
  const ordZ = "z".charCodeAt(0);
  const len = ordZ - ordA + 1;
  let s = "";
  while (n >= 0) {
    s = String.fromCharCode((n % len) + ordA) + s;
    n = Math.floor(n / len) - 1;
  }
  return s;
};

// Returns negative number if cell address1 is less than cell address2,
// And returns positive if cell address1 is greater than cell address2,
// And returns zero if equal.
// Cell address comparison is first by row then column.
export const compareCellAddress = (
  address1: string,
  address2: string
): number => {
  const regex = /([A-Z]+)\$?(\d+)/;
  const [, colA, rowA] = address1.match(regex) || [];
  const [, colB, rowB] = address2.match(regex) || [];

  return (
    +rowA - +rowB ||
    colA.length - colB.length ||
    (colA < colB ? -1 : colA > colB ? 1 : 0)
  );
};

// Sort cell addresses by row. Note: cell addresses may have $ sign.
// Example:
//	+ Input: [ A4, C7, B11, A7, F1]
//	+ Output: [ F1, A4, A7, C7, B11]
export const sortCellAddressByRow = (array: string[]): string[] =>
  array.sort(compareCellAddress);

// Return true if cell address1 is less than cell address2; otherwise, false.
// Cell address comparison is first by row then column.
// Examples of cell address comparisons are below,
//	+ A1 is less than A2
//	+ A1 is less than B1
//	+ B1 is less than A2
// 	+ A2 is less than B2
//	+ A9 is less than A10
export const isCellAddressLessThan = (address1, address2) =>
  compareCellAddress(address1, address2) < 0;
