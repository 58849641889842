<template>
  <fieldset class="mb-3">
    <legend>{{ label }}</legend>
    <div
      v-for="(resource, index) in resources"
      :key="index"
      class="row mb-2 gx-2"
    >
      <div class="col">
        <slot
          :row-data="resource"
          :index="index"
          :iteration="index + 1"
          :previous-row="resources[index - 1]"
          :next-row="resources[index + 1]"
          :is-first="index === 0"
          :is-last="index === resources.length - 1"
        />
      </div>
      <div class="col-auto" style="margin-top: 2rem">
        <button
          type="button"
          class="btn btn-outline-danger btn-icon"
          @click="deleteRow(index)"
        >
          −
        </button>
      </div>
    </div>
    <div class="form-row">
      <div class="col">
        <button
          type="button"
          class="btn btn-outline-success btn-icon"
          @click="addRow"
        >
          +
        </button>
      </div>
    </div>
  </fieldset>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["update:modelValue"],
  props: {
    modelValue: Array,
    label: String,
  },
  computed: {
    resources: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
  },
  methods: {
    addRow() {
      (this.resources || (this.resources = [])).push({});
    },
    deleteRow(index) {
      this.resources.splice(index, 1);
    },
  },
});
</script>
