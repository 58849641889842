import axios from "axios";
import { getAccessToken, randomString, refreshTokenCall } from "@/auth/oauth";

const Api = (redirectToLogin: () => void) => {
  let _refreshingCall: Promise<void> | undefined = undefined;

  const refreshToken = (): Promise<void> => {
    if (_refreshingCall) {
      return _refreshingCall;
    }

    return (_refreshingCall = refreshTokenCall()
      .finally(() => {
        _refreshingCall = undefined;
      })
      .catch(() => {
        // We were unable to refresh the token, so we redirect to log in
        redirectToLogin();
        return Promise.reject();
      }));
  };

  const axiosInstance = axios.create({
    baseURL: `${process.env.VUE_APP_TEAMS_DASHBOARD_API_HOST}/api`,
    headers: {
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
  });

  axiosInstance.interceptors.request.use(
    (config) => {
      const accessToken = getAccessToken();

      if (accessToken) {
        // add a random string to query params to disable cache
        config.params = { ...config.params, _rid: randomString(40) };

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        config.headers.Authorization = `Bearer ${accessToken}`;
        return config;
      } else {
        redirectToLogin();
        throw new axios.Cancel("Unauthenticated request canceled.");
      }
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      error.message = error?.response?.data?.message || error.message;

      if (error.response) {
        const { status } = error.response;

        // Handle Unauthenticated
        if (status === 401) {
          return refreshToken()
            .catch(() => {
              // We return the original error here instead of the refreshing token error
              return Promise.reject(error);
            })
            .then(() => {
              // We have refreshed the token, so we retry the original request
              return axiosInstance.request(error.config);
            });
        }

        // Handle Forbidden
        // else if (status === 403) {}

        // Handle Server Error
        // else if (status >= 500) {}
      }

      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default Api;
