/* global Office */
import { getRedirectUrl } from "@/auth/oauth";

export const openLoginDialog = async (
  onSuccess: () => void,
  onError: (x: string) => void
): Promise<void> => {
  let loginDialog: Office.Dialog;
  const dialogLoginUrl = getRedirectUrl();

  await Office.context.ui.displayDialogAsync(
    dialogLoginUrl,
    { height: 60, width: 30 },
    (result) => {
      if (result.status === Office.AsyncResultStatus.Failed) {
        onError(`${result.error.code} ${result.error.message}`);
      } else {
        loginDialog = result.value;
        loginDialog.addEventHandler(
          Office.EventType.DialogMessageReceived,
          processLoginMessage
        );
        loginDialog.addEventHandler(
          Office.EventType.DialogEventReceived,
          processLoginDialogEvent
        );
      }
    }
  );

  const processLoginMessage = (args) => {
    const messageFromDialog = JSON.parse(args.message);
    if (messageFromDialog.status === "success") {
      // We now have a valid access token.
      loginDialog.close();
      onSuccess();
    } else {
      // Something went wrong with authentication or the authorization of the web application.
      loginDialog.close();
      onError(messageFromDialog.reason);
    }
  };

  const processLoginDialogEvent = (args) => {
    onError(processDialogEvent(args));
  };

  const processDialogEvent = (args) => {
    switch (args.error) {
      case 12002:
        return "The dialog box has been directed to an invalid URL.";
      case 12003:
        return "The dialog box has been directed to a URL with the non-HTTPS protocol.";
      case 12006:
        // 12006 means that the user closed the dialog instead of waiting for it to close.
        // It is not known if the user completed the login or logout, so assume the user is
        // logged out and revert to the app's starting state. It does no harm for a user to
        // press the login button again even if the user is logged in.
        return "The dialog box closed unexpectedly.";
      default:
        return "Unknown error in the dialog box.";
    }
  };
};

export const sendFailureMessage = (code: string): void => {
  Office.context.ui.messageParent(
    JSON.stringify({
      status: "failure",
      reason: `Authorization failed (${code}).`,
    })
  );
};

export const sendSuccessMessage = (): void => {
  Office.context.ui.messageParent(JSON.stringify({ status: "success" }));
};
