import { createApp } from "vue";
import router from "./router";
import Api from "./prototypes/api";
import App from "./App.vue";

window.Office.onReady(() => {
  const app = createApp(App);
  app.use(router);
  app.config.globalProperties.$api = Api(() => {
    router.push({ name: "login" });
  });
  app.mount("#app");
});
