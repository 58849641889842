import axios from "axios";

const base64url = (buffer: Uint8Array): string =>
  btoa(String.fromCharCode.apply(null, Array.from(buffer)))
    .replace(/=/g, "")
    .replace(/\+/g, "-")
    .replace(/\//g, "_");

const randomBytes = (length: number): Uint8Array =>
  crypto.getRandomValues(new Uint8Array(length));

const hash = async (plain: string): Promise<Uint8Array> =>
  new Uint8Array(
    await crypto.subtle.digest("SHA-256", new TextEncoder().encode(plain))
  );

export const randomString = (length: number): string =>
  base64url(randomBytes((length * 3) / 4));

const buildUrl = (
  base: string,
  path = "",
  params?: string[][] | Record<string, string> | string | URLSearchParams
): string => `${base}${path}?${new URLSearchParams(params).toString()}`;

const generateAndStoreState = (): string => {
  const state = randomString(40);
  sessionStorage.setItem("state", state);
  return state;
};

const generateAndStoreVerifier = (): string => {
  const verifier = randomString(128);
  sessionStorage.setItem("verifier", verifier);
  return verifier;
};

const generateCodeChallenge = async (verifier: string): Promise<string> =>
  base64url(await hash(verifier));

const pullState = (): string | null => {
  const state = sessionStorage.getItem("state");
  sessionStorage.removeItem("state");
  return state;
};

const pullVerifier = (): string | null => {
  const verifier = sessionStorage.getItem("verifier");
  sessionStorage.removeItem("verifier");
  return verifier;
};

const storeTokens = (tokens: {
  access_token: string;
  refresh_token: string;
}): void => {
  localStorage.setItem("access_token", tokens.access_token);
  localStorage.setItem("refresh_token", tokens.refresh_token);
};

const getRefreshToken = (): string | null =>
  localStorage.getItem("refresh_token");

export const getAccessToken = (): string | null =>
  localStorage.getItem("access_token");

export const removeTokens = (): void => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
};

export const getRedirectUrl = (): string =>
  `${location.origin}/auth/redirect.html`;

const getCallbackUrl = (): string => `${location.origin}/auth/callback.html`;

export const getAuthorizationUrl = async (): Promise<string> => {
  const state = generateAndStoreState();
  const verifier = generateAndStoreVerifier();
  const challenge = await generateCodeChallenge(verifier);

  return buildUrl(
    process.env.VUE_APP_TEAMS_DASHBOARD_API_HOST,
    "/oauth/authorize",
    {
      client_id: process.env.VUE_APP_TEAMS_DASHBOARD_CLIENT_ID,
      redirect_uri: getCallbackUrl(),
      response_type: "code",
      scope: "",
      state: state,
      code_challenge: challenge,
      code_challenge_method: "S256",
      prompt: "login",
    }
  );
};

export const accessTokenCall = (): Promise<void> => {
  const url = new URL(location.href);
  const state = url.searchParams.get("state");
  if (!state || state !== pullState()) {
    return Promise.reject("state_mismatch");
  }

  const verifier = pullVerifier();
  if (!verifier) {
    return Promise.reject("no_code_verifier");
  }

  const code = url.searchParams.get("code");
  if (!code) {
    const error = url.searchParams.get("error");
    return Promise.reject(error || "no_code");
  }

  return axios({
    method: "post",
    baseURL: process.env.VUE_APP_TEAMS_DASHBOARD_API_HOST,
    url: "/oauth/token",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: new URLSearchParams({
      grant_type: "authorization_code",
      client_id: process.env.VUE_APP_TEAMS_DASHBOARD_CLIENT_ID,
      redirect_uri: `${url.origin}${url.pathname}`,
      code_verifier: verifier,
      code: code,
    }),
  })
    .catch((error) =>
      Promise.reject(
        error.response?.data?.message || error.message || "unknown_error"
      )
    )
    .then((response) => {
      if (response.data) {
        storeTokens(response.data);
        return Promise.resolve();
      } else {
        return Promise.reject("no_response");
      }
    });
};

export const refreshTokenCall = (): Promise<void> => {
  const refreshToken = getRefreshToken();

  if (!refreshToken) {
    return Promise.reject("no_refresh_token");
  }

  return axios({
    method: "post",
    baseURL: process.env.VUE_APP_TEAMS_DASHBOARD_API_HOST,
    url: "/oauth/token",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: new URLSearchParams({
      grant_type: "refresh_token",
      client_id: process.env.VUE_APP_TEAMS_DASHBOARD_CLIENT_ID,
      refresh_token: refreshToken,
    }),
  })
    .catch((error) =>
      Promise.reject(
        error.response?.data?.message || error.message || "unknown_error"
      )
    )
    .then((response) => {
      if (response.data) {
        storeTokens(response.data);
        return Promise.resolve();
      } else {
        return Promise.reject("no_response");
      }
    });
};
