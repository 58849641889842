import { createMemoryHistory, createRouter } from "vue-router";

function loadView(view) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  return require(`./components/${view}`).default;
  // return () => import(`./views/${view}`);
}

const router = createRouter({
  history: createMemoryHistory(),
  routes: [
    {
      path: "/",
      component: loadView("Layout"),
      children: [
        { path: "/", name: "home", component: loadView("Home") },
        // You may add additional routes here, like this:
        // { path: "/about", name: "about", component: loadView("About") },
      ],
    },
    { path: "/login", name: "login", component: loadView("Login") },
  ],
});

export default router;
